<template>
  <div class="">
    <ads-title
      name="广告设置信息"
      :class-list="['text-sm', 'font-semibold', 'py-2']"
    ></ads-title>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
    >
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item
            label="组内创意数"
            prop="count"
          >
            <el-input
              v-model.number="form.count"
              placeholder=""
              @change="emitMsg"
              type="number"
              :min="1"
              :max="30"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="素材类型"
            prop="mediaType"
          >
            <el-select
              v-model="form.mediaType"
              placeholder=""
              @change="emitMsg"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <ads-title
        name="广告语"
        :class-list="['text-sm', 'py-2']"
      ></ads-title>
      <el-form-item
        label=""
        label-width="0"
        prop="title"
      >
        <emoji-input
          v-model="form.title"
          palce="标题"
          @input="emitMsg"
        ></emoji-input>
      </el-form-item>
      <el-form-item
        label=""
        label-width="0"
        prop="description"
      >
        <emoji-input
          v-model="form.description"
          palce="描述"
          @input="emitMsg"
        ></emoji-input>
      </el-form-item>
      <el-form-item
        label=""
        label-width="0"
        prop="content"
      >
        <emoji-input
          v-model="form.content"
          palce="正文"
          type="textarea"
          @input="emitMsg"
        ></emoji-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import adsTitle from './adsTitle.vue';
import emojiInput from '@/views/adManagement/createAd/components/emojiInput.vue';
let checkCount = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('数量不能为空'));
  }
  setTimeout(() => {
    if (!Number.isInteger(value)) {
      callback(new Error('请输入数字值'));
    } else {
      if (value < 0 || value > 30) {
        callback(new Error('数量必选在1-30之前'));
      } else {
        callback();
      }
    }
  }, 0);
};
export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    campaign: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rules: {
        count: [{ validator: checkCount, trigger: ['blur', 'change'] }],
        mediaType: [{ required: true, message: '创意类型必须', trigger: ['blur', 'change'] }],
        content: [{ required: true, message: '正文必须', trigger: ['blur', 'change'] }],
      },
      form: {
        count: 1,
        mediaType: 1,
        content: '',
        description: '',
        title: '',
      },
      options: [
        { label: '图片', value: 2 },
        { label: '视频', value: 1 },
      ],
    };
  },
  components: {
    adsTitle,
    emojiInput,
  },
  watch: {
    campaign: {
      handler() {
        this.form = { ...this.campaign.creative };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async vaildForm() {
      return new Promise((resolve, reject) => {
        this.$refs['form'].validate((v) => {
          resolve(v);
        });
      });
    },
    emitMsg() {
      this.$emit('emitMsg', this.form, this.index);
    },
  },
};
</script>
<style lang="scss" scoped></style>
