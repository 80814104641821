<template>
  <div>
    <el-form-item
      label="个号"
      prop="thirdId"
    >
      <!-- <el-select v-model="ruleForm.thirdId" placeholder="请选择个号" @change="getAccountList">
                <el-option :label="item.name" :value="item.id" v-for="item in thirdUserList" :key="item.id"></el-option>
            </el-select> -->
      <el-cascader
        :options="thirdUserList"
        :show-all-levels="false"
        v-model="ruleForm.thirdId"
        placeholder="请选择个号"
        @change="getAccountList"
        :props="{ value: 'id', label: 'name', children: 'assetItemList' }"
        filterable
        clearable
      ></el-cascader>
    </el-form-item>
    <el-form-item
      label="广告账户"
      prop="adAccountId"
    >
      <el-select
        v-model="ruleForm.adAccountId"
        placeholder="请选择广告账户"
        @change="selectAccount"
        filterable
        clearable
      >
        <el-option
          :label="item.name"
          :value="item.id"
          v-for="item in accountList"
          :key="item.id"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-row :gutter="10">
      <el-col :span="12">
        <el-form-item
          label="主页"
          prop="pageId"
        >
          <!-- <el-select v-model="ruleForm.pageId" placeholder="请选择主页" @change="emitMsg">
                <el-option :label="item.name" :value="item.id" v-for="item in pagesList" :key="item.id"></el-option>
            </el-select> -->
          <el-select
            v-model="ruleForm.pageId"
            placeholder="请选择主页"
            @change="changePage"
            filterable
            clearable
            style="width: 80%"
          >
            <el-option
              v-for="page in pagesList"
              :key="page.id"
              :label="page.name"
              :value="page.id"
            >
              <div class="pageItems">
                <!-- <div class="pageImg">
                  <el-image :src="page.picture_url"></el-image>
                </div> -->
                <div class="pageMsg">
                  <span
                    ><el-tooltip
                      content="检测到提示主页已经被封"
                      v-if="page.isBlocked"
                      ><i
                        class="el-icon-lock"
                        style="color: #f00; margin-right: 6px"
                      ></i></el-tooltip
                    >{{ page.name }} # {{ page.id }}</span
                  >
                  <!-- <div>编号：{{ page.id }}</div> -->
                </div>
              </div>
              <!-- <set-page-status :page="page" @getList="getPagesList"></set-page-status> -->
            </el-option>
          </el-select>
          <div style="font-size: 12px; color: #999">
            总容量：{{ numMsg.limit_on_ads_running_or_in_review }}，已使用：{{ numMsg.ads_running_or_in_review_count }}
          </div>
        </el-form-item></el-col
      >
      <el-col :span="12">
        <el-form-item
          label="像素"
          prop="pixelId"
        >
          <el-select
            v-model="ruleForm.pixelId"
            placeholder="请选择像素"
            @change="emitMsg"
            filterable
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in pixelsList"
              :key="item.id"
            ></el-option>
          </el-select> </el-form-item
      ></el-col>
    </el-row>
  </div>
</template>
<script>
import {
  myLoginList,
  queryAdAccountByThirdUser,
  updatePixelByAccount,
  updatePageByAccount,
  pageVolumes,
} from '@/api/autoUploadGoods/common';
import setPageStatus from '@/views/adManagement/createAd/components/setPageStatus.vue';
export default {
  props: {
    platform: {
      type: String,
      default: 'facebook',
    },
    accountMsg: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ruleForm: {
        thirdId: '',
        adAccountId: '',
        pageId: '',
        pixelId: '',
      },
      thirdUserList: [],
      accountList: [],
      pixelsList: [],
      pagesList: [],
      numMsg: {},
    };
  },
  components: {
    setPageStatus,
  },
  watch: {
    accountMsg: {
      handler() {
        let { thirdId, adAccountId, pageId, pixelId } = this.accountMsg;
        this.ruleForm.thirdId = thirdId;
        this.getAccountList(this.setThirdUserId(thirdId), true);
        this.ruleForm.adAccountId = adAccountId;
        this.ruleForm.pageId = pageId;
        this.changePage(pageId);
        this.ruleForm.pixelId = pixelId;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    userId() {
      return this.$store.state.user.userInfo.data.id;
    },
  },
  methods: {
    setThirdUserId(thirdId) {
      if (typeof thirdId == 'string') {
        return thirdId;
      } else {
        return thirdId && thirdId.length ? thirdId[1] : '';
      }
    },
    // 获取个号列表
    getThirdUserList() {
      myLoginList({ platform: this.platform, gimpUserId: this.userId }).then((res) => {
        if (res.code == 0) {
          this.thirdUserList = res.data;
        } else {
          this.thirdUserList = [];
        }
      });
    },
    // 获取账户列表
    async getAccountList(v, defaultValue) {
      if (v) {
        await queryAdAccountByThirdUser({ platform: this.platform, thirdUserId: this.setThirdUserId(v) }).then(
          (res) => {
            if (res.code == 0) {
              this.accountList = res.data;
              this.ruleForm.adAccountId = defaultValue ? this.accountMsg.adAccountId : '';
              this.selectAccount(this.ruleForm.adAccountId, defaultValue);
              this.emitMsg();
            } else {
              this.accountList = [];
            }
          },
        );
      } else {
        this.accountList = [];
      }
    },
    // 选择账户
    selectAccount(v, defaultValue) {
      // 回显上次缓存信息的时候不清空
      if (!defaultValue) {
        this.ruleForm.pageId = '';
        this.ruleForm.pixelId = '';
        this.pixelsList = [];
        this.pagesList = [];
      }
      this.getPixelsList(v);
      this.getPagesList(v);
      // this.emitMsg()
    },
    changePage(v) {
      if (!v) return;
      pageVolumes(
        { accountId: this.ruleForm.adAccountId, pageId: v },
        { thirdLoginUserId: this.setThirdUserId(this.ruleForm.thirdId) },
      ).then((res) => {
        this.numMsg = res.data;
      });
      this.emitMsg();
    },
    // 获取像素列表
    async getPixelsList(v) {
      if (v) {
        await updatePixelByAccount({
          platform: this.platform,
          thirdUserId: this.setThirdUserId(this.ruleForm.thirdId),
          adAccounts: [v],
        }).then((res) => {
          if (res.code == 0) {
            this.pixelsList = res.data.pixelList;
          } else {
            this.pixelsList = [];
          }
        });
      } else {
        this.pixelsList = [];
      }
    },
    // 获取主页列表
    async getPagesList(v) {
      if (v) {
        await updatePageByAccount({
          platform: this.platform,
          thirdUserId: this.setThirdUserId(this.ruleForm.thirdId),
          adAccounts: [v],
        }).then((res) => {
          if (res.code == 0) {
            this.pagesList = res.data.commonPage;
          } else {
            this.pagesList = [];
          }
        });
      } else {
        this.pagesList = [];
      }
    },
    emitMsg() {
      this.$emit('setForm', {
        ...this.ruleForm,
        thirdId: this.setThirdUserId(this.ruleForm.thirdId),
        adAccountName: this.accountList.find((a) => a.id == this.ruleForm.adAccountId)?.name,
      });
    },
  },
  created() {
    this.getThirdUserList();
  },
};
</script>
<style lang="scss" scoped>
.el-form-item .el-select {
  width: 100%;
}
</style>
