<template>
  <div class="text-xs">
    <ads-title
      name="投放信息设置"
      :class-list="['text-base', 'font-semibold', 'py-2']"
    >
      <el-button
        type="text"
        @click="addCampaign"
        >添加</el-button
      >
    </ads-title>
    <div class="px-8">
      <el-row
        :gutter="10"
        style="width: calc(100% - 14px)"
        class="py-3 text-sm font-semibold"
      >
        <el-col :span="1">
          <span>序号</span>
        </el-col>
        <el-col :span="2">
          <span>广告组数量</span>
        </el-col>
        <el-col :span="4">
          <span>年龄</span>
        </el-col>
        <el-col :span="13">
          <span>受众</span>
        </el-col>
        <el-col :span="2">
          <span>性别</span>
        </el-col>
        <el-col :span="2">
          <span>操作</span>
        </el-col>
      </el-row>
      <el-collapse :value="activeCollapse">
        <el-collapse-item
          v-for="(campaign, index) in campaignList"
          :name="`index_${index}`"
          class="bg-gray-200"
        >
          <template slot="title">
            <adset-msg
              ref="adsetMsg"
              :index="index"
              @deleteCampaign="deleteCampaign"
              @emitMsg="setMsg"
              :countryCode="countryCode"
              :campaign="campaign"
            ></adset-msg>
          </template>
          <div>
            <ads
              ref="adsMsg"
              :index="index"
              :campaign="campaign"
              @emitMsg="setAdsMsg"
            ></ads>
            <bid-strage
              :initData="initData"
              ref="bidMsg"
              :index="index"
              @emitMsg="setBidMsg"
              :campaign="campaign"
            ></bid-strage>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
import adsetMsg from './adsetMsg.vue';
import adsTitle from './adsTitle.vue';
import ads from './ads.vue';
import bidStrage from './bidStrage.vue';
export default {
  props: {
    initData: {
      type: Object,
      default: () => {},
    },
    countryCode: {
      type: String,
      default: '',
    },
    adsetMsg: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      campaignList: [],
      activeCollapse: [],
      initCamapign: {
        creative: { count: 1, mediaType: 1 },
        adBudgetInfos: [],
        targetingInfo: {
          count: 1,
          minAge: '18',
          maxAge: '65',
          gender: 2,
          facebook_include_interests: [],
        },
      },
    };
  },
  components: {
    adsetMsg,
    adsTitle,
    ads,
    bidStrage,
  },
  computed: {},
  watch: {
    adsetMsg: {
      handler() {
        let msg = this.adsetMsg ? JSON.parse(JSON.stringify(this.adsetMsg)) : null;
        this.campaignList = msg && msg.length ? msg : [this.initCamapign];
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    initCampaignList() {
      this.campaignList = [this.initCamapign];
    },
    addCampaign() {
      this.campaignList.push(this.initCamapign);
    },
    deleteCampaign(index) {
      if (this.campaignList.length <= 1) {
        this.$message({
          type: 'warning',
          message: '请至少保留一项',
        });
        return;
      }
      console.log(this.campaignList, 'sssssssssss', index);
      this.campaignList.splice(index, 1);
      this.campaignList = JSON.parse(JSON.stringify(this.campaignList));
      console.log(this.campaignList);
    },
    setMsg(v, index) {
      this.campaignList[index] = { ...this.campaignList[index], ...v };
    },
    setAdsMsg(v, index) {
      this.campaignList[index] = { ...this.campaignList[index], creative: { ...v } };
    },
    setBidMsg(v, index) {
      this.$set(this.campaignList[index], 'adBudgetInfos', v);
    },
    setActiveCollapse() {
      let list = [];
      this.campaignList.forEach((item, index) => {
        let flag = false;
        // 第一次什么都不填的情况下，item = {}
        if (Object.keys(item).length > 0) {
          if (!item.creative?.count || item.creative?.content) flag = true;
          if (item.adBudgetInfos.length) {
            // 不填预算的情况下 item.adBudgetInfos = []
            item.adBudgetInfos.forEach((bidMsg) => {
              // 预算为空
              if ((bidMsg.isCbo && !bidMsg.lifetimeBudget) || (!bidMsg.isCbo && !bidMsg.dailyBudget)) {
                flag = true;
              }
              // 竞价金额为空
              if (bidMsg.bidStrategy != 'LOWEST_COST_WITHOUT_CAP' && !bidMsg.bidAmount) {
                flag = true;
              }
            });
          } else {
            flag = true;
          }
          if (flag) list.push(`index_${index}`);
        } else {
          list.push(`index_${index}`);
        }
      });
      this.activeCollapse = list;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
::v-deep .el-collapse-item__header {
  height: 60px;
  line-height: 1;
}
::v-deep .el-collapse-item__wrap {
  // margin-top: 10px;
  border: 1px solid #efefef;
  padding: 10px;
  box-shadow: 0 0 10px 5px #efefef;
  margin-bottom: 10px;
}
// :deep(.el-collapse-item__header){
//     pointer-events: none;
// }
// :deep(.el-collapse-item__arrow){
//     pointer-events: auto;
// }
</style>
