<template>
    <div>
        <div :class="classList.concat(['flex','justify-between','justify-items-center','pr-8'])">
            <p>{{ name }}</p>
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        classList: {
            type: Array,
            default: () => []
        },
        name: {
            type: String,
            default: () => '基本信息'
        }
    },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>
<style lang="scss" scoped></style>