<template>
  <div class="w-full h-full py-4">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
    >
      <el-row :gutter="10">
        <el-col :span="1">
          <el-form-item
            label=""
            @click.native.stop="aaa"
          >
            <span class="text-sky-500">{{ index + 1 }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item
            label=""
            prop="count"
            @click.native.stop="aaa"
          >
            <el-input
              v-model.number="form.count"
              placeholder=""
              @change="emitMsg"
              type="number"
              :min="1"
              :max="30"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-row>
            <el-col :span="8">
              <el-form-item label="">
                <el-select
                  v-model="form.minAge"
                  placeholder=""
                  @change="emitMsg"
                >
                  <el-option
                    v-for="item in ages"
                    :key="item"
                    :label="item"
                    :value="item"
                    :disabled="item > form.maxAge"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <p class="text-center leading-8">~</p>
            </el-col>
            <el-col :span="8">
              <el-form-item label="">
                <el-select
                  v-model="form.maxAge"
                  placeholder=""
                  @change="emitMsg"
                >
                  <el-option
                    v-for="item in ages"
                    :key="item"
                    :label="item"
                    :value="item"
                    :disabled="item < form.minAge"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>

        <el-col
          :span="13"
          @click.native.stop="addFlex"
        >
          <el-form-item
            label=""
            v-if="flexSpec.length"
          >
            <el-tag
              closable
              v-for="(tag, index) in flexSpec.slice(0, 3)"
              :key="index"
              style="margin: 0 4px"
              @close.stop="deleteTag(tag)"
              >{{ tag.name }}</el-tag
            >
            <el-tag v-if="flexSpec.length > 3"> + {{ flexSpec.length - 3 }}</el-tag>
          </el-form-item>
          <el-form-item
            label=""
            v-else
          >
            <el-input
              v-model="aa"
              placeholder="请选择兴趣词"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item label="">
            <el-select
              v-model="form.gender"
              placeholder=""
              @change="emitMsg"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item label="">
            <el-button
              type="text"
              size="mini"
              @click.stop="deleteCampaign"
              >删除</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <flex-dialog
      :addFlexShow.sync="addFlexShow"
      :flexData="flexData"
      @setFlexSpec="setFlexSpec"
    ></flex-dialog>
  </div>
</template>
<script>
import flexDialog from './flexDialog.vue';
let checkCount = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('数量不能为空'));
  }
  if (!Number.isInteger(value)) {
    callback(new Error('请输入数字值'));
  } else {
    if (value <= 0 || value > 30) {
      callback(new Error('数量必选在1-30之前'));
    } else {
      callback();
    }
  }
};
export default {
  props: {
    index: {
      type: Number,
      default: () => 0,
    },
    countryCode: {
      type: String,
      default: '',
    },
    campaign: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rules: {
        count: [{ validator: checkCount, trigger: ['blur', 'change'] }],
      },
      form: {
        count: 1,
        minAge: '18',
        maxAge: '65',
        gender: 2,
      },
      options: [
        { label: '全部', value: 2 },
        { label: '男', value: 1 },
        { label: '女', value: 0 },
      ],
      addFlexShow: false,
      flexData: {},
      aa: '',
      specialAgeRange: { ID: 21, TH: 20 },
      TargetingAgeRange: { min: 18, max: 65, minDefault: 18 },
    };
  },
  components: {
    flexDialog,
  },
  watch: {
    campaign: {
      handler() {
        this.form.count = this.campaign?.targetingInfo?.count || 1;
        this.form.gender = this.campaign?.targetingInfo?.gender || 2;
        this.form.maxAge = this.campaign?.targetingInfo?.maxAge || '65';
        this.form.minAge = this.campaign?.targetingInfo?.minAge || '18';
        let flexData = this.campaign?.targetingInfo?.facebook_include_interests
          ? JSON.parse(JSON.stringify(this.campaign?.targetingInfo?.facebook_include_interests))
          : [];
        console.log(flexData);
        this.flexData = flexData.length ? this.setFlexData(flexData) : {};
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    flexSpec() {
      if (Object.keys(this.flexData).length) {
        let list = [];
        Object.keys(this.flexData).forEach((item) => {
          list = list.concat(this.flexData[item]);
        });
        return list;
      } else {
        return [];
      }
    },
    locations() {
      return this.countryCode || '';
    },
    ages() {
      var list = [];
      let ageMinList = [];
      let locations = this.locations;
      // if (locations && locations.length) {
      //     locations.map(v => {

      //     })
      // }
      if (this.specialAgeRange[locations]) {
        ageMinList.push(this.specialAgeRange[locations]);
      }
      console.log(ageMinList);
      let min = ageMinList.length ? Math.max(...ageMinList) : this.TargetingAgeRange.min;
      this.$set(this.form, 'minAge', min);
      for (var i = min; i <= this.TargetingAgeRange.max; i++) {
        list.push(i);
      }

      return list;
    },
  },
  methods: {
    aaa() {},
    deleteTag(tag) {
      let type = tag.type;
      this.flexData[type] = this.flexData[type].filter((v) => v.id !== tag.id);
      this.emitMsg();
    },
    deleteCampaign(index) {
      this.$emit('deleteCampaign', this.index);
      // this.emitMsg();
    },
    addFlex(index) {
      this.addFlexShow = true;
    },
    setFlexData(flexData) {
      let obj = {};
      flexData.forEach((v) => {
        if (obj[v.type]) {
          obj[v.type].push(v);
        } else {
          obj[v.type] = [].concat(v);
        }
      });
      return obj;
    },
    setFlexSpec(v) {
      this.flexData = JSON.parse(JSON.stringify(v));
      this.emitMsg();
    },
    vaildForm() {
      return new Promise((resolve, reject) => {
        this.$refs['form'].validate((v) => {
          console.log('===', v);
          resolve(v);
        });
      });
    },
    emitMsg() {
      let msg = JSON.parse(JSON.stringify(this.form));
      msg.facebook_include_interests = JSON.parse(JSON.stringify(this.flexSpec));
      console.log(msg);
      this.$emit('emitMsg', { targetingInfo: { ...msg } }, this.index);
    },
  },
};
</script>
<style lang="scss" scoped></style>
