<template>
  <div>
    <ads-title
      name="基本信息"
      :class-list="['text-base', 'font-semibold', 'py-2']"
    ></ads-title>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      size="small"
    >
      <account-msg
        @setForm="setForm"
        ref="accountMsg"
        :accountMsg="accountMsg"
        @resetForm="resetForm"
      ></account-msg>
      <get-goods-by-link
        @setForm="setForm"
        ref="linkForm"
        :accountMsg="ruleForm"
        :id="id"
      ></get-goods-by-link>
      <el-form-item
        label="投放地区"
        prop="countryCode"
      >
        <el-select
          v-model="ruleForm.countryCode"
          placeholder="请选择地区"
          clearable
          filterable
          @change="setCountry"
        >
          <el-option
            :label="item.desc"
            :value="item.key"
            v-for="item in initData.countryList"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item
            label="受益方"
            prop="dsaBeneficiary"
          >
            <el-input
              v-model="ruleForm.dsaBeneficiary"
              placeholder="受益方"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="赞助方"
            prop="dsaPayor"
          >
            <el-input
              v-model="ruleForm.dsaPayor"
              placeholder="赞助方"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import getGoodsByLink from './getGoodsByLink.vue';
import accountMsg from './accountMsg.vue';
import adsTitle from './adsTitle.vue';

export default {
  props: {
    initData: {
      type: Object,
      default: () => {},
    },
    accountMsg: {
      type: Object,
      default: () => {},
    },
    defaultConfigAdBeneficiary: {
      type: Object,
      default: () => {},
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ruleForm: {
        countryCode: '',
        promoteUrl: '',
        thirdId: '',
        adAccountId: '',
        pixelId: '',
        pageId: '',
        dsaBeneficiary: '',
        dsaPayor: '',
      },
      rules: {
        countryCode: { required: true, message: '请选择投放地区', trigger: ['change'] },
        promoteUrl: { required: true, message: '请输入投放链接', trigger: ['change', 'blur'] },
        thirdId: { required: true, message: '请选择个号', trigger: ['change', 'blur'] },
        adAccountId: { required: true, message: '请选择账户', trigger: ['change', 'blur'] },
        pixelId: { required: true, message: '请选择像素', trigger: ['change', 'blur'] },
        pageId: { required: true, message: '请选择主页', trigger: ['change', 'blur'] },
        dsaBeneficiary: { required: false, message: '受益方', trigger: ['change', 'blur'] },
        dsaPayor: { required: false, message: '赞助方', trigger: ['change', 'blur'] },
      },
    };
  },
  components: {
    getGoodsByLink,
    accountMsg,
    adsTitle,
  },
  watch: {
    accountMsg: {
      handler() {
        this.ruleForm = { ...this.ruleForm, ...this.accountMsg, ...this.defaultConfigAdBeneficiary };
      },
      deep: true,
      immediate: true,
    },
    defaultConfigAdBeneficiary: {
      handler() {
        this.ruleForm.dsaBeneficiary = this.defaultConfigAdBeneficiary.dsaBeneficiary;
        this.ruleForm.dsaPayor = this.defaultConfigAdBeneficiary.dsaPayor;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        return valid;
      });
    },
    resetForm(formName) {
      this.$refs[formName]?.clearValidate();
    },
    setForm(v) {
      this.ruleForm = { ...this.ruleForm, ...v };
      this.resetForm('ruleForm');
      if (v.site?.countryCode) {
        this.ruleForm.countryCode = v.site.countryCode || '';
      }
      console.log(this.ruleForm.countryCode);
    },
    setCountry(v) {
      this.$emit('setCountry', v);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form-item .el-select {
  width: 100%;
}
</style>
<style lang="scss">
.el-scrollbar__wrap {
  overflow-x: auto;
}
</style>
